import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { LicenseModule } from '@/store/modules/license';
import { AuthModule } from '@/store/modules/auth';
let AuthLoadingPage = class AuthLoadingPage extends Vue {
    constructor() {
        super(...arguments);
        this.redirectTo = this.$route.query.redirectTo?.toString() || '/home';
        this.loaded = false;
    }
    mounted() {
        this.load();
    }
    async load() {
        this.$loading('Loading application assets');
        await LicenseModule.validateLicense();
        await AuthModule.fetchMe();
        if (LicenseModule.licenseValid && AuthModule.isAuthenticated) {
            this.loaded = true;
            await this.$router.replace(decodeURIComponent(this.redirectTo)).catch(error => {
                // if one of the resolvers (probably) cancelled navigation to requested resource
                // and user still on the loading page we need to redirect him to the home page
                // @example:
                // User requests on initial loading /org/:id/backups/:backupId/objects where
                // {backupId} isn't exists and resolver prevents redirecting to this page
                // user will be stuck on the loading page without any chance to use application
                if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.aborted)) {
                    if (error.from?.name === 'auth.loading') {
                        this.$router.replace({
                            name: 'main.home',
                        });
                    }
                }
            });
        }
    }
};
AuthLoadingPage = __decorate([
    Component({})
], AuthLoadingPage);
export default AuthLoadingPage;
